import { FC } from "react";

import { theme } from "@/theme";

import {
  CustomTypographyProps,
  StyledDynamicBreakpointTypography,
  StyledDynamicTypography,
  TypographyVarient,
} from "./typography.styles";

const dynamicTag = (varient: TypographyVarient) => {
  return varient === "displayxl" ||
    varient === "displayl" ||
    varient === "displays" ||
    varient === "heading1"
    ? "h1"
    : varient === "heading2"
      ? "h2"
      : varient === "heading3"
        ? "h3"
        : varient === "heading4"
          ? "h4"
          : varient === "heading5"
            ? "h5"
            : varient === "heading6"
              ? "h6"
              : varient === "subheadingl"
                ? "h6"
                : varient === "bodyl"
                  ? "p"
                  : varient === "bodys"
                    ? "p"
                    : varient === "bodyxs"
                      ? "p"
                      : varient === "captionl"
                        ? "span"
                        : varient === "captions"
                          ? "span"
                          : "p";
};
// default is bodys
const Typography: FC<CustomTypographyProps> = ({
  children,
  mdVarient,
  mdWeightVarient,
  varient,
  weightVarient,
  className,
  color,
  ...rest
}) => {
  if (mdVarient) {
    return (
      <StyledDynamicBreakpointTypography
        as={dynamicTag(varient!)}
        className={className}
        varient={varient}
        weightVarient={weightVarient}
        mdVarient={mdVarient}
        mdWeightVarient={mdWeightVarient}
        color={color || theme.colors.gray[900]}
        {...rest}
      >
        {children}
      </StyledDynamicBreakpointTypography>
    );
  }
  return (
    <StyledDynamicTypography
      as={dynamicTag(varient!)}
      className={className}
      varient={varient}
      weightVarient={weightVarient}
      color={color || theme.colors.gray[900]}
      {...rest}
    >
      {children}
    </StyledDynamicTypography>
  );
};

export default Typography;
